import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import { prypcoEnv } from './prypco.env';
import { composeMsalEndpointsConfig } from './utils/msal-util';

const fullHost = `${location.protocol}//${location.host}`;

const isDev = false;
const enableLogs = false;

export const tokenScope = '464d4303-043f-4656-9a12-9e80b969c9b9';

export const signUpAuthority = isDev
  ? 'https://auth.prypco.com/devprypto.onmicrosoft.com/B2C_1_sign-up-email'
  : 'https://auth.prypco.com/devprypto.onmicrosoft.com/B2C_1_customer-sign-up';

export const signUpSalesAgentAuthority =
  'https://auth.prypco.com/devprypto.onmicrosoft.com/B2C_1_sign-up-sales-agent';

export const resetPasswordAuthority =
  'https://auth.prypco.com/devprypto.onmicrosoft.com/B2C_1_reset-password';

export const signInAuthority =
  'https://auth.prypco.com/devprypto.onmicrosoft.com/B2C_1_sign-up-sign-in';

export const msalApp = new PublicClientApplication({
  auth: {
    clientId: tokenScope,
    authority: signInAuthority,
    knownAuthorities: ['auth.prypco.com'],
    redirectUri: `${fullHost}/auth`,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: enableLogs
      ? {
          logLevel: LogLevel.Verbose,
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                // eslint-disable-next-line no-restricted-syntax
                console.info(message);
                return;
              case LogLevel.Verbose:
                // eslint-disable-next-line no-restricted-syntax
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
            }
          },
          piiLoggingEnabled: false,
        }
      : {},
  },
});

export const msalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
};

const protectedResourceMap = composeMsalEndpointsConfig(
  [
    {
      endpoint: prypcoEnv.endpoints.mortgageLeads,
      methods: ['POST', 'GET', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.servicesLeads,
      methods: ['POST', 'GET', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.onboarding,
      methods: ['POST'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.onboarding}/Blocks/Redo`,
      methods: ['POST'],
    },
    {
      endpoint: prypcoEnv.endpoints.user,
      methods: ['GET', 'POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.applicationForms,
      methods: ['GET', 'POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.dictionaries}/Users`,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.applicants,
      methods: ['GET', 'POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.mortgageDocs,
      methods: ['GET', 'POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.loanOffers,
      methods: ['GET', 'POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.referenceData,
      methods: ['POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.userBaseApi,
      methods: ['GET', 'PATCH'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.userBaseApi}/AccountStates`,
      methods: ['POST'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.userBaseApi}/User/Backoffice`,
      methods: ['POST'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/Properties`,
      methods: ['GET', 'POST'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/Card`,
      methods: ['GET', 'POST'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/Finance`,
      methods: ['GET', 'POST'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/Finance/Transactions`,
      methods: ['GET', 'POST', 'PATCH'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/Wallet`,
      methods: ['GET', 'POST', 'DELETE', 'PATCH'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/Cart`,
      methods: ['GET'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/Cart/Item`,
      methods: ['POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/DirectDebit/PaymentSources`,
      methods: ['GET'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.propertyInvestment}/Certificates/Download`,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.salesAgencies,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.listingProperty,
      methods: ['GET', 'POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.documents,
      methods: ['GET', 'POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.listingLandlord,
      methods: ['GET', 'POST', 'PATCH', 'DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.blocksOnboarding,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.blocksLead,
      methods: ['GET', 'PATCH'],
    },
    {
      endpoint: prypcoEnv.endpoints.dueDiligence,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.dev.lead,
      methods: ['DELETE'],
    },
    {
      endpoint: prypcoEnv.endpoints.dev.user,
      methods: ['POST'],
    },
    {
      endpoint: prypcoEnv.endpoints.bank,
      methods: ['GET'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.leadBase}/banks/offers/selection`,
      methods: ['PUT'],
    },
    {
      endpoint: prypcoEnv.endpoints.notificationBaseApi,
      methods: ['POST'],
    },
    {
      endpoint: prypcoEnv.endpoints.notificationHub,
      methods: ['GET', 'POST'],
    },
    {
      endpoint: prypcoEnv.endpoints.propertyInvestmentCheckout,
      methods: ['POST'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.leadBase}/Brokers`,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.blocks.investor,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.leadBase,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.teams,
      methods: ['GET'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.leadBase}/addresses`,
      methods: ['PATCH'],
    },
    {
      endpoint: `${prypcoEnv.endpoints.leadBase}/banks/offerTemplate`,
      methods: ['POST'],
    },
    {
      endpoint: prypcoEnv.endpoints.brokerages,
      methods: ['GET'],
    },
    {
      endpoint: prypcoEnv.endpoints.brokers,
      methods: ['GET'],
    },
  ],
  tokenScope,
);

export const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap,
};
